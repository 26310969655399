import React, { useState } from "react";

const EarlyAccessPopup = () => {
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted email:", email);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-3xl w-full overflow-hidden flex relative font-['Nohemi',sans-serif]">
                <div
                    className="w-2/3 p-8 space-y-6 relative"
                    style={{ clipPath: "url(#curve)" }}
                >
                    <div className="space-y-2 mt-8">
                        <h2 className="text-xl font-light text-blue-900">
                            COMING SOON
                        </h2>
                        <h3 className="text-3xl font-bold text-blue-900">
                            Sign Up Now to get
                            <br />
                            Early Access
                        </h3>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-normal text-gray-700"
                            >
                                Email-ID
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="name@email.com"
                                className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 font-light"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-900 text-white font-bold py-3 px-4 rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Get Access
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EarlyAccessPopup;
